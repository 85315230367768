import React, { Component } from 'react'
import Modal from 'react-bootstrap/Modal'
import Form from 'react-bootstrap/Form'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { connect } from 'react-redux'
import { fetchStatusHistory } from './actions/phaseAction'
import {
  getLifeCycles,
  getPhases,
  savePhase,
  getStatuses,
  getReasons,
  getActions,
  getSavedStatusesAndReasons,
} from './api'
import StatusAndReasonSelect from './StatusAndReasonSelect'
import Button from '../engine-ui/Button'
import TriggeredActionsList from '../engine-ui/TriggeredActionsList'
import Spinner from '../engine-ui/Spinner'
import { FormSelect } from 'react-bootstrap'

class PhasePopup extends Component {
  state = {
    open: false,
    submitEnabled: false,
    showActions: false,
    actions: [],
    statuses: [],
    valid: false,
    phaseOptionsValue: '',
    statusAndReasonOptionsValues: [],
    allPhases: [],
    futurePhases: [],
    currentPhase: '',
    requestPhase: '',
    showCheckboxes: false,
    selectedActions: [],
    statusAndReasonExist: false,
    allowToSetRepeatedly: false,
    isLoading: false,
    reasonIsMissing: false,
    errorMessage: '',
  }

  componentDidMount() {
    const { requestId, productsWithLifeCycles, productName } = this.props

    if (productsWithLifeCycles.includes(productName)) {
      getLifeCycles(requestId).then((data) => {
        this.setState({
          futurePhases: data ? data.futurePhases : [],
          currentPhase: data?.currentPhase,
          requestPhase: data?.requestPhase,
          allPhases: data ? data.allPhases : [],
        })
      })
    } else {
      getPhases(requestId).then((data) => {
        this.setState({
          futurePhases: data ? data.futurePhases : [],
          currentPhase: data?.currentPhase,
          requestPhase: data?.requestPhase,
          allPhases: data ? data.allPhases : [],
        })
      })
    }

    getStatuses(requestId).then((data) => {
      if (data) {
        this.setState({ statuses: data.data })
      }
    })
  }

  handleClickToOpen = () => {
    this.setState({
      open: true,
      submitEnabled: false,
      valid: false,
      showActions: false,
      showCheckboxes: false,
      phaseOptionsValue: '',
      actions: [],
      selectedActions: [],
      statusAndReasonOptionsValues: [{ status: '', reason: '' }],
      statusAndReasonExist: false,
      errorMessage: '',
    })
  }

  handleClickToClose = () => {
    this.setState({ open: false })
  }

  handleCheck = () => {
    let proceed = true
    const { phaseOptionsValue, statusAndReasonOptionsValues, currentPhase } =
      this.state
    const { requestId } = this.props
    if (phaseOptionsValue === currentPhase) {
      proceed = window.confirm(
        'Du bist dabei, die gleiche Phase wieder zu setzen. Alle Aktionen (inkl. E-Mails an Kunden und Gegenseite) werden erneut ausgelöst. WAHRSCHEINLICH IST ES EIN VERSEHEN. BIST DU SICHER, DASS DU DIESE PHASE ERNEUT SETZEN MÖCHTEST?',
      )
    }

    if (!proceed) return
    if (this.checkIfAlreadyExist()) return
    if (this.checkIfReasonPresent()) {
      this.setState({ reasonIsMissing: true })
      return
    }
    this.setState({ reasonIsMissing: false })

    const flatStatusAndReason = [].concat(
      ...statusAndReasonOptionsValues.map((item) => Object.values(item)),
    )

    this.setState({ isLoading: true })

    getActions([phaseOptionsValue, ...flatStatusAndReason], requestId)
      .then((response) => {
        if (response.ok) {
          return response.json()
        } else {
          this.setState({
            isLoading: false,
            errorMessage: 'Ein Problem ist aufgetreten',
          })
        }
      })
      .then((data) => {
        this.setState({
          isLoading: false,
          actions: data.actions,
          valid: data.valid,
          showActions: true,
          submitEnabled: data.valid,
          selectedActions: data.actions.map((action) => action.id),
        })
      })
      .catch((err) => console.error(err))
  }

  handleSubmit = () => {
    const { phaseOptionsValue, statusAndReasonOptionsValues, selectedActions } =
      this.state
    const { productsWithLifeCycles, productName, requestId, dispatch } =
      this.props
    const phaseToSave = phaseOptionsValue ? phaseOptionsValue : ''
    this.setState({
      open: false,
      showActions: false,
      submitEnabled: false,
    })

    const flatStatusAndReason = [].concat(
      ...statusAndReasonOptionsValues.map((item) => Object.values(item)),
    )
    savePhase(requestId, [phaseToSave, ...flatStatusAndReason], selectedActions)
      .then(() => {
        if (productsWithLifeCycles.includes(productName))
          return getLifeCycles(requestId)
        else return getPhases(requestId)
      })
      .then((data) => {
        this.setState({
          futurePhases: data.futurePhases,
          currentPhase: data.currentPhase,
          requestPhase: data.requestPhase,
          id: data.id,
        })

        if (phaseOptionsValue) {
          V2.trigger('phase.updated', {})
          const event = new Event('phase.updated')
          document.dispatchEvent(event)
        }
        return data
      })
      .then(() => {
        return getSavedStatusesAndReasons(requestId)
      })
      .then((data) => {
        fetchStatusHistory(dispatch, {
          data: data.data,
          id: new Date().toISOString(),
        })

        return getStatuses(requestId)
      })
      .then((data) => {
        this.setState({ statuses: data.data })
      })
      .catch((error) => {
        console.error('Error saving phase:', error)
        this.setState({
          errorMessage: 'Failed to save phase. Please try again.',
        })
      })
  }

  handlePhaseChange = (event) => {
    this.setState({
      phaseOptionsValue: event.target.value,
    })
  }

  handleStatusChange = (event, index) => {
    const statusId = event.target.value
    const { requestId } = this.props
    const { statusAndReasonOptionsValues } = this.state
    let arrayCopy = [...statusAndReasonOptionsValues]
    arrayCopy[index].status = statusId
    this.setState({
      statusAndReasonOptionsValues: arrayCopy,
    })

    getStatuses(requestId).then((data) => {
      return data?.data.map((item) => {
        if (item.id == statusId) {
          this.setState({
            allowToSetRepeatedly: item.attributes.allow_to_set_repeatedly,
          })
        }
      })
    })

    if (!statusId) return
    getReasons(requestId, statusId).then((data) => {
      this.setState({
        [statusId]: data.data,
      })
    })
  }

  handleReasonChange = (event, index) => {
    const reasonId = event.target.value
    const { statusAndReasonOptionsValues } = this.state
    let arrayCopy = [...statusAndReasonOptionsValues]
    arrayCopy[index].reason = reasonId
    this.setState({
      statusAndReasonOptionsValues: arrayCopy,
    })
  }

  removeNewStatus = (index) => {
    const { statusAndReasonOptionsValues } = this.state
    let arrayCopy = [...statusAndReasonOptionsValues]
    arrayCopy.splice(index, 1)
    this.setState(
      {
        statusAndReasonOptionsValues: arrayCopy,
      },
      () => {
        this.checkIfAlreadyExist()
      },
    )
  }

  renderPhaseOptions = () => {
    const { futurePhases } = this.state
    return futurePhases.map((val, index) => {
      return (
        <option key={index} value={val[1]}>
          {val[0]}
        </option>
      )
    })
  }

  renderReasonOptions = (status) => {
    if (!status) return []
    const reasons = this.state[status]
    if (!reasons) return []
    return reasons.map((val, index) => {
      return (
        <option key={index} value={val.id}>
          {val.attributes.title}
        </option>
      )
    })
  }

  renderStatuses = () => {
    const { productsWithLifeCycles, productName } = this.props
    if (productsWithLifeCycles.includes(productName)) {
      return (
        <div>
          <div className="d-flex">
            <div className="w-50">
              <Form.Label>Status</Form.Label>
            </div>
            <div className="w-50">
              <Form.Label>Grund</Form.Label>
            </div>
          </div>

          {this.state.statusAndReasonOptionsValues.map((item, index) => {
            return (
              <StatusAndReasonSelect
                key={`new${index}`}
                index={index}
                status={item.status}
                reason={item.reason}
                valid={this.state.valid}
                onStatusChange={this.handleStatusChange}
                onReasonChange={this.handleReasonChange}
                renderReasonOptions={this.renderReasonOptions}
                renderStatusOptions={this.renderStatusOptions}
                removeNewStatus={this.removeNewStatus}
                existingStatusAndReason={false}
                reasonIsMissing={this.state.reasonIsMissing}
              />
            )
          })}
          <div className="add-status-btn__container">
            <Button
              className="btn add-status-btn"
              onClick={this.addSelectStatus}
              disabled={this.state.submitEnabled}
            >
              <i className="fas fa-lg fa-plus-circle text-white me-1" />
              Status hinzufügen
            </Button>
          </div>
        </div>
      )
    }
  }

  addSelectStatus = () => {
    const { statusAndReasonOptionsValues } = this.state
    this.setState({
      statusAndReasonOptionsValues: [
        ...statusAndReasonOptionsValues,
        { status: '', reason: '' },
      ],
    })
  }

  statusOptionsGrouper = (acc, status) => {
    const majorCategory = status.major_category
    var skipOthers = false

    if (status.life_cycle_uuids.includes(this.selectedLifeCycle())) {
      skipOthers = true
      const lcTitle = status.life_cycle_title
      if (!Array.isArray(acc[lcTitle])) {
        acc[lcTitle] = []
      }
      acc[lcTitle].push(status)
    }

    if (!Array.isArray(acc[status.major_category_title])) {
      acc[status.major_category_title] = []
    }

    if (!(majorCategory === 'other' && skipOthers)) {
      acc[status.major_category_title].push(status)
    }
    return acc
  }

  selectedLifeCycle = () => {
    const { currentPhase, phaseOptionsValue } = this.state
    return phaseOptionsValue && !phaseOptionsValue !== ''
      ? phaseOptionsValue
      : currentPhase
  }

  renderOptions = (list) => {
    return list.map((status, index) => {
      return (
        <option key={`s-${index}`} value={status.id}>
          {status.title}
        </option>
      )
    })
  }

  sortedStatusOptionsKeys = (grouped) => {
    const presentKeys = Object.keys(grouped)
    const lastKeys = presentKeys
      .filter((key) => {
        return key === 'Andere' || key === 'Allgemein'
      })
      .sort() // Generic first, other second

    return presentKeys
      .filter((key) => {
        return key !== 'Andere' && key !== 'Allgemein'
      }) // to put them in the end
      .sort()
      .concat(lastKeys)
  }

  renderStatusOptions = () => {
    const { statuses } = this.state
    const grouped = statuses
      .map((status) => {
        return Object.assign({ id: status.id }, status.attributes)
      })
      .reduce(this.statusOptionsGrouper, [])

    return this.sortedStatusOptionsKeys(grouped).map((key, index) => {
      return (
        <optgroup key={`g-${index}`} label={key}>
          {this.renderOptions(grouped[key])}
        </optgroup>
      )
    })
  }

  handleActionCheck = (event) => {
    const { selectedActions } = this.state
    if (event.target.id === 'select-all') {
      if (event.target.checked) {
        this.setState({
          selectedActions: this.state.actions.map((action) => action.id),
        })
      } else {
        this.setState({
          selectedActions: [],
        })
      }
      return
    }
    if (selectedActions.includes(event.target.id)) {
      const index = selectedActions.indexOf(event.target.id)
      this.setState({
        selectedActions: [
          ...selectedActions.slice(0, index),
          ...selectedActions.slice(index + 1),
        ],
      })
    } else {
      this.setState({
        selectedActions: [...selectedActions, event.target.id],
      })
    }
  }

  checkIfAlreadyExist = () => {
    const { productsWithLifeCycles, productName } = this.props
    if (!productsWithLifeCycles.includes(productName)) return false
    const filteredTableRows = this.props.tableRows.data
      .map((item) => item.attributes)
      .filter((item) => !item.deleted_at)

    const tableRowsArray = filteredTableRows.map((item) =>
      [item.status_uuid, item.reason_uuid].toString(),
    )

    const statusAndReasonArray = this.state.statusAndReasonOptionsValues.map(
      (item) => [item.status, item.reason].toString(),
    )

    const matchedStatusAndReason = tableRowsArray
      .map((item) => statusAndReasonArray.includes(item))
      .includes(true)

    if (matchedStatusAndReason && !this.state.allowToSetRepeatedly) {
      this.setState({
        statusAndReasonExist: true,
      })
    } else {
      this.setState({
        statusAndReasonExist: false,
      })
    }
    return matchedStatusAndReason && !this.state.allowToSetRepeatedly
  }

  checkIfReasonPresent = () => {
    return this.state.statusAndReasonOptionsValues.filter(
      (item) =>
        !item.reason && item.status && this.state[item.status].length !== 0,
    ).length !== 0
      ? true
      : false
  }

  renderActions = () => {
    const {
      showActions,
      actions,
      showCheckboxes,
      selectedActions,
      statusAndReasonExist,
      allowToSetRepeatedly,
    } = this.state

    if (statusAndReasonExist && !allowToSetRepeatedly) {
      return (
        <div className="actions-triggered__item mx-3 w-100">
          <h5 className="actions-triggered__item-ttl">
            <i className="fas fa-exclamation-triangle text-danger mb-1"></i>
            Status und Reason existieren bereits
          </h5>
        </div>
      )
    }

    if (!showActions) return null

    if (actions.length) {
      return (
        <div>
          <TriggeredActionsList
            data={actions}
            showCheckboxes={showCheckboxes}
            onActionCheck={this.handleActionCheck}
            selectedActions={selectedActions}
            withSelectAll
          />
        </div>
      )
    } else {
      return (
        <div className="actions-triggered__item mx-3 w-100">
          <h5 className="actions-triggered__item-ttl">
            <i className="far fa-check-circle color-primary"></i>
            Es werden keine Aktionen ausgelöst
          </h5>
        </div>
      )
    }
  }

  toggleEdit = () => {
    this.setState((prevState) => ({
      showCheckboxes: !prevState.showCheckboxes,
    }))
  }

  getCurrentPhaseName = () => {
    const { allPhases, currentPhase } = this.state
    if (!allPhases || !currentPhase) return null
    else return allPhases.filter((phase) => phase.includes(currentPhase))[0][0]
  }

  render() {
    const {
      requestPhase,
      open,
      valid,
      phaseOptionsValue,
      statusAndReasonOptionsValues,
      submitEnabled,
    } = this.state

    const { productsWithLifeCycles, productName } = this.props

    const phaseBtnClass = classNames({
      phasegraph__currentphase: true,
      cp_short: requestPhase?.length <= 3,
      cp_long: requestPhase?.length > 3,
    })

    const flatArrayStatusAndReason = []
      .concat(
        ...statusAndReasonOptionsValues.map((item) => Object.values(item)),
      )
      .filter((item) => item)

    const checkDisabled =
      phaseOptionsValue === '' && flatArrayStatusAndReason.length === 0
        ? true
        : false

    const textTitle = !productsWithLifeCycles.includes(productName)
      ? 'Phase'
      : 'Lifecycle'

    const textCurrentTitle = !productsWithLifeCycles.includes(productName)
      ? 'Aktuelle Phase'
      : 'Aktueller Lifecycle'

    return (
      <div>
        <Button onClick={this.handleClickToOpen} className={phaseBtnClass}>
          {requestPhase}
        </Button>

        <Modal show={open} onHide={this.handleClickToClose} size="lg">
          <Modal.Header closeButton className="modal-lifecycle">
            <Modal.Title>{textTitle}</Modal.Title>
          </Modal.Header>
          <Modal.Body className="p-4 modal-lifecycle">
            <div>
              <Form.Group controlId="currentPhase" className="mb-2">
                <Form.Label>{textCurrentTitle}</Form.Label>
                <FormSelect disabled>
                  <option> {this.getCurrentPhaseName()} </option>
                </FormSelect>
              </Form.Group>
              <Form.Group controlId="newPhase" className="mb-2">
                <Form.Label>Neuen {textTitle} auswählen</Form.Label>
                <FormSelect
                  name="phaseOptionsValue"
                  onChange={this.handlePhaseChange}
                  disabled={valid}
                  selected
                >
                  <option></option>
                  {this.renderPhaseOptions()}
                </FormSelect>
              </Form.Group>
            </div>
            {this.renderStatuses()}
            {this.state.isLoading ? (
              <Spinner />
            ) : this.state.errorMessage ? (
              <p className="text-danger">{this.state.errorMessage}</p>
            ) : (
              this.renderActions()
            )}
          </Modal.Body>
          <Modal.Footer className="v2modal-fields d-flex justify-content-end">
            <Button
              onClick={this.handleCheck}
              disabled={checkDisabled || submitEnabled}
              className="btn btn-info btn-rounded"
            >
              Überprüfen
            </Button>
            <Button
              onClick={this.toggleEdit}
              disabled={!submitEnabled}
              className="btn btn-warning btn-rounded"
            >
              Bearbeiten
            </Button>
            <Button
              onClick={this.handleSubmit}
              disabled={!submitEnabled}
              className="btn btn-primary btn-rounded"
            >
              Setzen
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    tableRows: state.statusTab.tableRows,
  }
}

PhasePopup.propTypes = {
  requestId: PropTypes.number,
  productName: PropTypes.string,
  tableRows: PropTypes.object,
  productsWithLifeCycles: PropTypes.array,
}

export default connect(mapStateToProps)(PhasePopup)
